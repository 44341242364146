import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Breadcrumbs = ({ breadcrumbs, variant }) => {

  return (
    <BreadcrumbsBlock variant={ variant }>
      { breadcrumbs ? breadcrumbs.map((crumb, i) => (
        <li key={ crumb.text }>
          { i !== breadcrumbs.length - 1
              ? <Link to={ crumb.url }>{crumb.text}</Link>
              : <p>{crumb.text}</p>
          }
        </li>
      )) : null }
    </BreadcrumbsBlock>
  )
}

export default Breadcrumbs

const BreadcrumbsBlock = styled.ul `
  position: ${props => props.variant === 'page' ? 'absolute' : 'static'};
  transform: ${props => props.variant === 'page' ? 'translate(-50%)' : 'none'};
  margin-top: ${props => props.variant === 'article' ? '2rem !important' : '0'};
  bottom: 6rem;
  left: 50%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1280px) {
    margin-top: ${props => props.variant === 'article' ? '1rem !important' : '0'};
  }

  @media only screen and (max-width: 480px) {
    overflow: scroll;
    -ms-overflow-style: none;  // Internet Explorer 10+
    scrollbar-width: none;  // Firefox
    margin-top: 1.4rem !important;

    &::-webkit-scrollbar {
      display: none;  // Safari and Chrome
    }
  }

  li {
    a,p {
      display: inline;
      font-size: ${props => props.variant === 'company' ? '1.3rem' : '1.4rem'};
      line-height: 1;
      color: ${props => props.variant === 'company' ? props.theme.blue : props.theme.white};
      opacity: ${props => props.variant === 'company' ? '1' : '0.5'};
      transition: ${props => props.theme.transition};

      @media only screen and (max-width: 1080px) {
        font-size: 1.3rem;
      }

      @media only screen and (max-width: 480px) {
        white-space: nowrap;
      }

      &:hover {
        opacity: 1;
      }
    }

    &:last-child {
      p {
        color: ${props => props.variant === 'company' ? props.theme.black : props.theme.white};
        opacity: 1;
      }
    }
  }

  li + li {
    margin-top: 0;
    margin-left: 1.5rem;
    padding-left: 2rem;
    position: relative;

    @media only screen and (max-width: 480px) {
      margin-left: 1rem;
      padding-left: 1.4rem;
    }

    &::before {
      content: '|';
      position: absolute;
      top: 0.7rem;
      left: 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0;
      color: ${props => props.variant === 'company' ? props.theme.black : props.theme.white};
      opacity: 0.4;
    }
  }
`
