import React from 'react'
import styled from 'styled-components'

const Article = ({ children }) => {
  return (
    <ArticleBlock>
      { children }
    </ArticleBlock>
  )
}

export default Article

const ArticleBlock = styled.div `

  flex: 1;
  max-width: 1180px;
  width: 60%;
  margin-right: 2rem;
  padding: 4rem;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.shadowBlock};

  @media only screen and (max-width: 1080px) {
    padding: 3rem;
  }

  @media only screen and (max-width: 980px) {
    padding: 2rem;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  figure {
    margin: 0;
    padding: 2rem;
    background-color: ${props => props.theme.grayExtraLight};
    text-align: center;

    @media only screen and (max-width: 480px) {
        padding: 1.2rem;
    }

    span {
      width: 100%;
    }

    figcaption {
      display: block;
      margin: 0;
      margin-top: 1rem;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: 0.01em;
      text-align: center;
      color: ${props => props.theme.blueGray};

      @media only screen and (max-width: 480px) {
        font-size: 1.2rem;
      }
    }

    .gatsby-image-wrapper img {
      object-fit: contain !important;
    }
  }

  > * + figure {
    margin-top: 2.4rem;
  }

  > * + hr {
    margin-top: 2rem;
  }

  ul, ol {
    padding-left: 3rem;
  }

  ol {
    list-style-type: decimal;

    li {
      padding-left: 1rem;
    }
  }

  .Article__thesis {
    padding: 1.4rem;
    border-left: 3px solid ${props => props.theme.sky};
    font-style: italic;

    @media only screen and (max-width: 480px) {
      margin-left: 1rem;
      padding-left: 1.6rem;
    }
  }

  .Article__thesis + .Article__resume {
    margin-top: 2rem;
  }

  .Article__resume {
    margin-top: 1rem;
    padding: 3rem;
    background-color: ${props => props.theme.grayExtraLight};

    @media only screen and (max-width: 480px) {
      padding: 2rem;
    }

    p, li, strong {
      font-size: 1.5rem;
    }

    * + p, li, strong {
      margin-top: 1rem;
    }
  }

  ol.Article__resume {
    padding-left: 4rem;

    @media only screen and (max-width: 480px) {
      padding-left: 3.6rem;
    }
  }
`
