import React from 'react'
import styled from 'styled-components'
import Img from '../../components/Img'
import Breadcrumbs from '../Breadcrumbs'

const ArticleHero = ({ heroTitle, heroImage, heroCategory, breadcrumbs }) => {

  const getCatogory = (nodes) => (nodes.filter(node => node.name !== 'Все посты')[0].name)

  return (
    <ArticleHeroBlock>
      { heroImage
        ? <Img styles="ArticleHero__img" localFile={ heroImage.node.localFile } alt={ heroTitle } />
        : null
      }
      <div className="ArticleHero__content">
        <small>{ getCatogory(heroCategory) }</small>
        <h1>{ heroTitle }</h1>
        <Breadcrumbs breadcrumbs={ breadcrumbs } variant="article" />
      </div>
    </ArticleHeroBlock>
  )
}

export default ArticleHero

const ArticleHeroBlock = styled.section `

  position: relative;
  margin-bottom: 2rem;
  max-height: 430px;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;

  @media only screen and (max-width: 1600px) {
    border-radius: 0;
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: 1rem;
  }

  .ArticleHero__content {
    position: absolute;
    bottom: 4rem;
    left: 4rem;
    width: 94%;

    @media only screen and (max-width: 1080px) {
      bottom: 3rem;
      left: 3rem;
    }

    @media only screen and (max-width: 480px) {
      bottom: 2.6rem;
      left: 50%;
      transform: translateX(-50%);
    }

    h1 {
      margin-bottom: 0;
      font-size: 4.3rem;
      line-height: 1.4;
      color: ${props => props.theme.white};

      @media only screen and (max-width: 1080px) {
        font-size: 3.6rem;
        line-height: 1.3;
      }

      @media only screen and (max-width: 480px) {
        font-size: 3.2rem;
        line-height: 1.2;
      }
    }

    small {
      display: inline-block;
      padding: 0.8rem;
      margin-bottom: 1.6rem;
      background-color: rgba(255,255,255, 0.3);
      color: ${props => props.theme.white};

      @media only screen and (max-width: 480px) {
        font-size: 1.1rem;
      }
    }
  }

  .ArticleHero__img {
    position: relative;

    @media only screen and (max-width: 1280px) {
      min-height: 400px;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: #031421;
      mix-blend-mode: normal;
      opacity: 0.5;
    }
  }
`