import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from '../Img'

const ArticleSimilar = ({ similar }) => {

  const getCatogory = (nodes) => (nodes.filter(node => node.name !== 'Все посты')[0].name)

  return (
    <ArticleSimilarBlock>
      { similar && similar.map(post => (
        <li className="ArticleSimilar__item" key={ post.id }>
          <Img styles="ArticleSimilar__img" localFile={ post.postThumb.postThumb?.localFile } alt={ post.title } />
          <div className="ArticleSimilar__content">
            <small>{ getCatogory(post.categories.nodes) }</small>
            <Link className="ArticleSimilar__link" to={ post.uri }>{ post.title }</Link>
          </div>
        </li>
      ))}
    </ArticleSimilarBlock>
  )
}

export default ArticleSimilar

const ArticleSimilarBlock = styled.ul `

  list-style-type: none;

  .ArticleSimilar__item {
    display: flex;
    justify-content: space-between;
    height: 80px;
    overflow: hidden;

    @media only screen and (max-width: 780px) {
      height: 70px;
    }
  }

  .ArticleSimilar__img {
    flex: 1 0 30%;
    margin-right: 2rem;

    @media only screen and (max-width: 780px) {
      margin-right: 1.5rem;
    }
  }

  .ArticleSimilar__content {
    flex: 1 1 70%;
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 13px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 58.7%);
    }

    small {
      margin-bottom: 0.6rem;
    }
  }

  .ArticleSimilar__item + .ArticleSimilar__item {
    margin-top: 1.4rem;
  }

  .ArticleSimilar__link {
    display: block;
    font-family: Montserrat;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.01em;
    color: ${props => props.theme.black};
    transition: all 0.2s ease-in;

    @media only screen and (max-width: 1280px) {
      font-size: 1.6rem;
    }

    @media only screen and (max-width: 780px) {
      font-size: 1.4rem;
      line-height: 1.2;
    }

    &:hover {
      color: ${props => props.theme.blue};
    }
  }
`
